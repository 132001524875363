/* Source https://codesandbox.io/p/sandbox/react-text-replace-transition-cz08h?file=%2Fsrc%2Fstyles.css%3A15%2C1-33%2C1&from-embed= */

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
