
.card {
	@apply bg-card xs:rounded-md sm:rounded-2xl m-[1.2%] lg:m-2;
}

.circle {
	@apply bg-card rounded-full w-14 h-14 lg:w-20 lg:h-20;
}

.standard-rounded {
	@apply xs:rounded-lg sm:rounded-2xl;
}

.rounded-top-inherit {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.rounded-bottom-inherit {
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}
