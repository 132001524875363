@font-face {
	font-family: "Alien Encounters";
	src: url("../../assets/fonts/Alien-Encounters-Italic.ttf");
}

@font-face {
	font-family: "Coca Cola ii";
	src: url("../../assets/fonts/Coca-Cola-ii.ttf");
}

@font-face {
	font-family: "junegull";
	src: url("../../assets/fonts/junegull-rg.otf");
}

@font-face {
	font-family: "VCR OSD Mono";
	src: url("../../assets/fonts/VCR_OSD_MONO_1.001.ttf");
}

@font-face {
	font-family: "Wanted LET";
	src: url("../../assets/fonts/Wanted LET Plain.ttf");
}

@font-face {
	font-family: "Brian James";
	src: url("../../assets/fonts/BrianJames.ttf");
}

@font-face {
	font-family: "Good Times";
	src: url("../../assets/fonts/good-times-rg.otf");
}

@font-face {
	font-family: "Rockabye";
	src: url("../../assets/fonts/Rockabye-Regular.otf");
}

