.shuffle .pushable {
	position: relative;
	background: transparent;
	padding: 0px;
	border: none;
	cursor: pointer;
	outline-offset: 4px;
	transition: filter 250ms;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.shuffle .shadow {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: hsl(0, 4%, 15%);
	filter: blur(2px);
	will-change: transform;
	transform: translateY(4px) translateX(-4px);
	transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.shuffle .edge {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.shuffle .front {
	display: block;
	position: relative;
	transform: translateY(4px) translateX(-4px);
	transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.shuffle .pushable:hover .front {
	transform: translateY(6px) translateX(-6px);
	transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.shuffle .pushable:active .front {
	transform: translateY(-2px);
	transition: transform 34ms;
}

.shuffle .pushable:hover .shadow {
	transform: translateY(-4px);
	transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.shuffle .pushable:active .shadow {
	transform: translateY(1px);
	transition: transform 34ms;
}

.shuffle .pushable:focus:not(:focus-visible) {
	outline: none;
}